import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

const BlockErrorWarnings = inject("stores") (
    observer (
        class BlockErrorWarnings extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeOrders = this.props.stores.storeOrders;
                
                this.state = {

                }
            }

            componentDidMount = async() => {
                const result = await this.storeOrders.getOrderErrors(this.props.orderId);

                this.setState({
                    errors: result
                })
            }

            handleLink = () => {
                const url = this.urlAPI + '/public/docs/NUR-lijst-versie-2011.pdf';
                window.open(url);
            }

            render() {
                return (   
                    <React.Fragment>   
                    {this.state.errors && this.state.errors.length > 0 &&
                        <span
                            className="list--errors__header"
                        >
                            LET OP
                        </span>      
                    }
                    <ul className="block block-list list--errors">
                        {this.state.errors && this.state.errors.map((error) =>
                            <li
                                className="list--errors__listitem"
                               
                            >
                                <span 
                                    className="list--errors__listitem--field" 
                                    dangerouslySetInnerHTML={{ __html: error}}
                                />
                            </li>
                        )}
                    </ul>
                    </React.Fragment> 
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockErrorWarnings));
