import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import * as ui from '../../utilities/ui';

import Template from '../../templates/template';
import WikiSections from '../../components/wiki/wiki-sections';
import WikiFeature from '../../components/wiki/wiki-feature';

const WikiStart = inject("stores") (
    observer (
        class WikiStart extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeWiki = this.props.stores.storeWiki;

                this.featureRef = React.createRef();

                this.state = {
                    wikiSection: 'startpage',
                    wikiFeature: 'newsletter',
                    feature: ''
                }
            }

            componentDidMount = () => {
            }

            componentWillUpdate = () => {
                const arr = ('rulesfrequent', 'rulesmanual', 'rulesstock');

                // if (arr.includes(this.storeWiki.activeFeature)) {
                //     this.setState({
                //         feature: ''
                //     });
                // }
            }

            handleFeature = (feature) => {
                let sku;
                let title;
                switch(feature) {
                    case 'daily':
                        sku = 'rulesmanual';
                        title = 'Handmatige processen';
                        break;
                    case 'frequent':
                        sku = 'rulesfrequent';
                        title = 'Veelvoorkomende handelingen';
                        break;
                    case 'stock':
                        sku = 'rulesstock';
                        title = 'Voorraad & Stock codes';
                        break;
                    default:
                };                 

                this.setState({
                    feature: feature
                });

                this.storeWiki.setActiveFeature(sku);
                this.storeWiki.setActiveFeatureTitle(title);               
            }

            scrollToFeature = () => {
                ui.smoothScrollTo(0, this.featureRef.offsetTop - 10, 500)
            }

            render() {
                return (                  
                    <Template
                        type = 'wiki'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Wiki</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Sections & features</h2>
                                    <div class="content--section__form-actions">
                                        <span>Shortcuts: </span>
                                        <button 
                                            className={"btn btn--secundary" + (this.state.feature === 'frequent' ? " --selected" : "")}
                                            onClick={() => this.handleFeature('frequent')}
                                        >
                                            Veelvoorkomend
                                        </button>
                                        <button 
                                            className={"btn btn--secundary" + (this.state.feature === 'daily' ? " --selected" : "")}
                                            onClick={() => this.handleFeature('daily')}
                                        >
                                            Handmatig
                                        </button>
                                        <button 
                                            className={"btn btn--secundary" + (this.state.feature === 'stock' ? " --selected" : "")}
                                            onClick={() => this.handleFeature('stock')}
                                        >
                                            Stock codes
                                        </button>
                                    </div>
                                </div>
                           
                                <div className="wrapper--content__columns">
                                    <div className="content-column content-column--full">
                                        <WikiSections 
                                            scrollToFeature = {this.scrollToFeature}
                                        />
                                    </div>
                                </div>
                            </div>

                            
                                <div 
                                    className="content--section"
                                    ref={ (ref) => this.featureRef=ref }
                                >
                                    {this.storeWiki.activeFeatureTitle !== '' &&
                                        <React.Fragment>
                                            <div className="content--section__header">
                                                <h2>{this.storeWiki.activeFeatureTitle}</h2>
                                            </div>
                                            <WikiFeature 
                                                wikiSection = {this.storeWiki.activeSection}
                                                wikiFeature = {this.storeWiki.activeFeature}
                                            />
                                        </React.Fragment>    
                                    }
                                </div>
                            
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(WikiStart));
