import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import TextInput from '../../elements/form-input-text';

import CellOrderAddress from './cell-order-address';
import CellOrderSummary from './cell-order-summary';
import CellOrderStatus from './cell-order-status';
import CellOrderHistory from './cell-order-history';
import BlockErrorWarnings from '../blocks/block-error-warnings';

const OrdersRow = inject("stores") (
    observer (
        class OrdersRow extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlAPI = process.env.REACT_APP_URL_API;
                
                this.state = {
                    order: {},
                    items: [],
                    show_date: false,
                    formControls: {
                        date: {
                            value: '',
                            touched: false,
                            placeholder: 'DD-MM-YY',
                            validationRules: {},
                            required: false
                        }
                    }
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {
                const items = await this.storeOrders.getOrderItemsData(this.props.order.order_id);

                this.setState({
                    order: this.props.order,
                    order_id: this.props.order_id,
                    order_cb: this.props.order_cb,
                    order_fulfilled: this.props.order_fulfilled,
                    order_onaccount: this.props.order.order_onaccount,
                    order_paid: this.props.order_paid,
                    order_banktransfer: this.props.order_banktransfer,
                    items: items
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.order_id !== this.props.order_id) {
                    const items = await this.storeOrders.getOrderItemsData(this.props.order_id);
                    this.setState({
                        items: items
                    })
                }

                if (prevProps.order_fulfilled !== this.props.order_fulfilled) {
                    this.setState({
                        order_fulfilled: this.props.order_fulfilled
                    })
                }

                if (prevProps.order_paid !== this.props.order_paid) {
                    this.setState({
                        order_paid: this.props.order_paid
                    })
                }

                if (prevProps.order_onaccount !== this.props.order_onaccount) {
                    this.setState({
                        order_onaccount: this.props.order.order_onaccount
                    })
                }

                if (prevProps.order_banktransfer !== this.props.order_banktransfer) {
                    this.setState({
                        order_banktransfer: this.props.order_banktransfer
                    })
                }

                if (prevProps.order_cb !== this.props.order_cb) {
                    this.setState({
                        order_cb: this.props.order_cb && this.props.order_cb
                    })
                }
            }

            handleChange = (event) => {  
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedControls[name] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                }); 
            }

            setFulfilled = async() => {
                const status = await this.storeOrders.setOrderStatus(this.props.order_id, 'fulfilled');
                const order = await this.storeOrders.getOrder(this.props.order.order_id);
                
                this.setState({
                    order: order[0],
                    order_fulfilled: order[0].order_fulfilled,
                })
            }

            setOrderedCB = async(e) => {
                const status = await this.storeOrders.setOrderStatus(this.props.order.order_id, 'ordered_cb');
                const order = await this.storeOrders.getOrder(this.props.order.order_id);
                
                this.setState({
                    order: order[0],
                    order_cb: order[0].order_cb.date,
                })
            }

            setPaid = async(e) => {
                this.setState({
                    show_date: !this.state.show_date
                })
            }

            setDatePaid = async(e) => {
                const status = await this.storeOrders.setOrderStatus(this.props.order.order_id, 'paid', moment(this.state.formControls.date.value).format("DD-MM-YY"));
                const order = await this.storeOrders.getOrder(this.props.order.order_id);
                this.setState({
                    order: order[0],
                    order_paid: order[0].order_paid,
                    show_date: !this.state.show_date
                })
            }

            setOnAccount = async(e) => {
                const status = await this.storeOrders.setOrderStatus(this.props.order_id, 'onaccount');
                const order = await this.storeOrders.getOrder(this.props.order.order_id);
                
                this.setState({
                    order: order[0],
                    order_onaccount: order[0].order_onaccount,
                })
            }

            viewInvoice = () => {

            }

            render() {
                return (       
                    <React.Fragment>
                    <tr className="table--orders__row-header">
                        <td colspan="5">
                            <span className="table--orders__cell-value">
                                <Link 
                                    to = {{ 
                                        pathname: "/admin-order/" + this.props.order.order_id,
                                        order_id: this.props.order.order_id,
                                        order: this.state.order
                                    }}
                                >
                                    {this.props.order.order_id}
                                </Link>
                            </span>
                        </td>
                        <td>
                            <Link 
                                to = {{ 
                                    pathname: "/admin-customer/" + this.props.order.client_id,
                                    client_id: this.props.order.client_id,
                                    client: this.props.client
                                }}
                            >
                                {this.props.order.client_id}
                            </Link>
                        </td>
                    </tr>          
                    <tr>
                        <td>
                            <BlockErrorWarnings 
                                orderId = {this.props.order.order_id}
                            />
                            {this.state.order.invoice_address !== this.state.order.delivery_address &&
                                <div
                                    className="table--orders__cell-alert"
                                >
                                    Factuur- en afleveradres zijn verschillend
                                </div>
                            }
                        </td>
                        <td>
                            <CellOrderAddress
                                order_id = {this.props.order.order_id}
                                order = {this.props.order}
                                nr = {this.state.items.length}
                            />
                            
                        </td>
                        <td className="table--orders-overview__cell-summary">
                            <CellOrderSummary
                                view = {'summary'}
                                order_id = {this.props.order.order_id}
                                order_fulfilled = {this.state.order_fulfilled}
                            />
                        </td>
                        <td>
                            <CellOrderHistory
                                order = {this.props.order}
                                order_id = {this.props.order_id}
                                order_cb ={this.state.order_cb}
                                order_paid ={this.state.order_paid}
                                order_onaccount = {this.state.order_onaccount}
                                order_fulfilled = {this.state.order_fulfilled}
                                order_banktransfer = {this.state.order_banktransfer}
                            />
                        </td>
                        <td>
                            <CellOrderStatus
                                order = {this.props.order}
                                order_cb ={this.state.order_cb}
                                order_paid ={this.state.order_paid}
                                order_onaccount = {this.state.order_onaccount}
                                order_fulfilled = {this.state.order_fulfilled}
                                order_banktransfer = {this.state.order_banktransfer}
                            />
                        </td>
                    </tr>
                    {!this.state.show_date &&
                        <React.Fragment> 
                        <tr>
                            <td className="table--orders-overview__form-actions" colspan="5">
                                {(this.state.order_paid === null ||
                                (this.state.order_cb === null && this.state.order_fulfilled === null) ||
                                (this.state.order_onaccount === null && this.state.order_paid === null) ||
                                this.state.order_fulfilled === null) &&
                                    <span>Verander orderstatus naar:</span>
                                }
                                {this.state.order_paid === null &&
                                    <button className="btn btn--secundary" onClick={(e) => this.setPaid(e)}>
                                        Betaling ontvangen
                                    </button>
                                }
                                {(this.state.order_paid === null && this.state.order_onaccount === null && this.state.order_fulfilled === null) &&
                                    <button className="btn btn--secundary" onClick={(e) => this.setOnAccount(e)}>
                                        Order op rekening
                                    </button>
                                }
                                {this.state.order_cb === null && this.state.order_fulfilled === null &&
                                    <button className="btn btn--secundary" onClick={(e) => this.setOrderedCB(e)}>
                                        Besteld bij CB
                                    </button>
                                }
                                {this.state.order_fulfilled === null &&
                                    <button className="btn btn--secundary" onClick={(e) => this.setFulfilled(e)}>
                                        Afgehandeld
                                    </button>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className="table--orders-overview__form-actions" colspan="5">
                                {this.props.order.order_fulfilled !== null
                                ?   <a href={this.urlAPI + "/invoices/invoice.php?GUID=" + this.props.order.order_guid} className="btn btn--secundary" target="_blank">Bekijk of print factuur</a>
                                :   <a href={this.urlAPI + "/invoices/invoice.php?GUID=" + this.props.order.order_guid} className="btn btn--secundary" target="_blank">Preview pdf bestelling</a> 
                                }
                            </td>
                        </tr>
                        </React.Fragment> 
                    }
                    {this.state.show_date &&
                        <tr>
                            <td className="table--orders-overview__form-actions" colspan="5">
                                <span>Datum betaling: </span>
                                <TextInput 
                                    name = 'date' 
                                    placeholder = {this.state.formControls['date'].placeholder}
                                    value = {this.state.formControls['date'].value}
                                    onChange = {this.handleChange}
                                />
                                <button className="btn btn--secundary" onClick={(e) => this.setPaid(e)}>
                                        Annuleer
                                </button>
                                <button className="btn btn--secundary" onClick={(e) => this.setDatePaid(e)}>
                                        Verstuur
                                </button>
                            </td>
                        </tr>
                    }
                    </React.Fragment> 
                )
            }
        }
    )
)

export default withTranslation()(withRouter(OrdersRow));
