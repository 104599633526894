import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import CBReportDaily from '../../components/cb/cb-report-daily';
import CBUpdatesDaily from '../../components/cb/cb-updates-daily';
import CBUpdatesDailyAuto from '../../components/cb/cb-updates-daily-auto';

const CBCheckUpdates = inject("stores") (
    observer (
        class CBCheckUpdates extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;
                
                this.state = {
                    formControls: {
                        dir_select: {
                            label: 'Kies update directorie',
                            value: 1,
                            placeholder: 'Kies update directorie',
                            touched: false,
                            options: ['2001', '2002']
                        }
                    },
                    feedback: ''
                }
            }

            componentDidMount = async() => {
                const dirs = await this.storeCb.getDailyDirs();

                this.setState({
                    dirs: dirs
                })
            }

            checkUpdates = async() => {
                this.setState({
                    updates: [],
                    feedback: ''
                })

                const date = this.state.formControls.dir_select.value;
                const updates = await this.storeCb.checkUpdates2008(date);

                this.setState({
                    updates: updates, 
                    feedback: 'success'
                })
            }

            handleChange = (event, action) => {  
                const name = event.target.name;
                let value = event.target.value;
         
                let updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedControls[name] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                })
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'cb'
                        feature = 'cbdaily'
                        title = 'Dagelijkse updates'
                    >   
                        <React.Fragment>
                        <div className="wrapper--content__header">
                                <h1>Dagelijkse updates - automatisch</h1>
                            </div>
                            <CBUpdatesDailyAuto />

                            <div className="wrapper--content__header">
                                <h1>Dagelijkse updates - handmatig</h1>
                            </div>
                            <CBUpdatesDaily />

                            <div className="wrapper--content__header">
                                <h1>Initieer updates handmatig</h1>
                            </div>
                            <CBReportDaily />                                                 
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CBCheckUpdates));
