import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from "moment";
import * as sort from '../../utilities/sort';
import Loader from '../../elements/loader';

const CBUpdatesDailyAuto = inject("stores") (
    observer (
        class CBUpdatesDaily extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_CUSTOM_NODE_ENV;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;

                this.state = {
                    view: 5
                }
            }

            componentDidMount = async() => {
                const status = await this.storeCb.getInitReports('DAILY');
            }

            clearUpdates = async() => {
                const cleared = await this.storeCb.clearUpdates();
            }

            getUpdateReports = async() => {
                const status = await this.storeCb.getInitReports('DAILY');
            }

            startCbDaily = async(filename, date) => {
                let loaded;
                this.storeCb.clearFeedback();

                if (filename == 'all') {
                    for (let file of this.storeCb.files_daily) {
                        if (file.status !== 'ok') {
                            loaded = await this.storeCb.initCatalog('INIT_DAILY', file.filename, file.date);
                        }
                    }
                }
                if (filename == 'day') {
                    for (let file of this.storeCb.files_daily) {
                        if (file.date ===  date) {
                            loaded = await this.storeCb.initCatalog('INIT_DAILY', file.filename, file.date);
                        }
                    }
                } 
                if  (filename !== 'all' && filename !== 'day') {
                    loaded = await this.storeCb.initCatalog('INIT_DAILY', filename, date);
                }
            }

            startResourcesDaily = async(filename, date) => {
                
            }

            toggleView = (days) => {
                this.setState({
                    view: this.state.view === days ? 5 : days
                })
            }

            render() {
                const arrDates = this.storeCb.dates.sort((a,b) => b - a);

                return (
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>Dagelijkse updates server-side</h2>
                        </div>
                    
                        <div className="content--section__columns">
                            <div className="content--section__column">
                                <h5>UPDATE 29-11-2024</h5>
                                <p>De dagelijks updates van covers/backcovers/pdf's en audio-bestanden worden nu automatisch gedaan via een automated task op de server.<br/>
                                Deze wordt eenmaal 's ochtends uitgevoerd.</p>
                                <p>Hierbij worden de volgende bestanden aangeroepen om achtereenvolgens de media op te hale:<br/>
                                - https://www.succesboeken.nl/api/v2/admin/cb/downloads/covers<br/>
                                - https://www.succesboeken.nl/api/v2/admin/cb/downloads/backcovers<br/>
                                - https://www.succesboeken.nl/api/v2/admin/cb/downloads/pdfs<br/>
                                - https://www.succesboeken.nl/api/v2/admin/cb/downloads/audio<br/>
                                </p>
                                <p>De scripts halen de bestanden van de huidige dag op.<br/>
                                Om bestanden van vorige dag op te halen kan de dag aan de url toegevoegd worden in het format "Ymd":<br/>
                                - /20241120<br/>
                                Bijv covers voor 20 november 2024 wordt:<br/>
                                - https://www.succesboeken.nl/api/v2/admin/cb/downloads/covers/20241120
                                </p>
                                <p>Onderstaande mogelijkheid om dagelijks handmatig de bestanden op te halen werkt nog wel, maar is overbodig.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(CBUpdatesDailyAuto);
