import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

const WikiFeatures = inject("stores") (
    observer (
        class WikiFeatures extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeWiki = this.props.stores.storeWiki;

                this.state = {
                    features: []
                }
            }

            componentDidMount = async() => {
                const features = await this.storeWiki.getFeatures(this.props.wikiSection);
                this.setState({
                    features: features
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.wikiSection !== this.props.wikiSection) {
                    const features = await this.storeWiki.getFeatures(this.props.wikiSection);
                    this.setState({
                        features: features
                    })
                }
            }

            handleFeature = (e, feature) => {
                this.storeWiki.setActiveFeature(feature.sku);
                this.storeWiki.setActiveFeatureTitle(feature.title);

                this.props.scrollToFeature();
            }

            render() {
                return (
                    <div className="container--card-wiki">
                        <ul
                            className="wiki-list--selection"
                        >
                            {this.state.features && this.state.features.map((feature) => 
                                <li 
                                    className = {(this.storeWiki.activeFeature === feature.sku ? "--active" : "x") + " --link"}
                                    onClick={(e) => this.handleFeature(e, feature)}
                                >
                                    {feature.title}
                                </li>
                            )}
                       </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(WikiFeatures);
