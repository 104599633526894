import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

const WikiFeature = inject("stores") (
    observer (
        class WikiFeature extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeWiki = this.props.stores.storeWiki;

                this.state = {
                    featureBlocks: [],
                    featureProcedures: [],
                    featureDevelopment: [],
                    featureRemarks: []
                }
            }

            componentDidMount = async() => {
                const featureBlocks = await this.storeWiki.getFeatureBlocks(this.props.wikiFeature);
                const featureProcedures = await this.storeWiki.getFeatureProcedures(this.props.wikiFeature);
                const featureDevelopment = await this.storeWiki.getFeatureDevelopment(this.props.wikiFeature);
                const featureRemarks = await this.storeWiki.getFeatureRemarks(this.props.wikiFeature);

                this.setState({
                    featureBlocks: featureBlocks,
                    featureProcedures: featureProcedures,
                    featureDevelopment: featureDevelopment,
                    featureRemarks: featureRemarks
                })
            }

            componentDidUpdate = async(prevProps) => {
                
                if (prevProps.wikiFeature !== this.props.wikiFeature) {
                    const featureBlocks = await this.storeWiki.getFeatureBlocks(this.props.wikiFeature);
                    const featureProcedures = await this.storeWiki.getFeatureProcedures(this.props.wikiFeature);
                    const featureDevelopment = await this.storeWiki.getFeatureDevelopment(this.props.wikiFeature);
                    const featureRemarks = await this.storeWiki.getFeatureRemarks(this.props.wikiFeature);

                    this.setState({
                        featureBlocks: featureBlocks,
                        featureProcedures: featureProcedures,
                        featureDevelopment: featureDevelopment,
                        featureRemarks: featureRemarks
                    })
                }

                if (prevProps.wikiSection !== this.props.wikiSection) {
                    this.setState({
                        featureBlocks: [],
                        featureProcedures: [],
                        featureDevelopment: [],
                        featureRemarks: []
                    })
                }
            }

            handleWikiLink = async(e, sku) => {
                this.storeWiki.setActiveFeature(sku);

                const feature = await this.storeWiki.getFeature(sku);
                const featureTitle = feature.title;
                this.storeWiki.setActiveFeatureTitle(featureTitle);
            }

            render() {

                return (
                    <div className="container--card-wiki__feature">
                        <div>
                            <h4>Omschrijving</h4>
                            {this.state.featureBlocks && this.state.featureBlocks.map((featureBlock) => 
                                <div>
                                    {(featureBlock.title !== '' && featureBlock.title !== null) &&
                                        <h5>{featureBlock.title}</h5>
                                    }
                                    <p
                                        dangerouslySetInnerHTML={{ __html: featureBlock.description}}
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            className = "container--card-wiki__procedure"
                        >
                            <h4>Procedures gebruiker</h4>
                            {this.state.featureProcedures && this.state.featureProcedures.map((featureProcedure) => 
                                <React.Fragment>
                                    <div
                                        className="container--card-wiki__feature--step"
                                    >
                                        {(featureProcedure.step !== '' && featureProcedure.step !== null) &&
                                        <React.Fragment>
                                            <h5>STAP {featureProcedure.step}</h5>
                                            {(featureProcedure.title !== '' && featureProcedure.title !== null) &&
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: ':&nbsp'}} 
                                                />
                                            }
                                            </React.Fragment>
                                        }
                                        {(featureProcedure.title !== '' && featureProcedure.title !== null) &&
                                            <h5>
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: featureProcedure.title}} 
                                                />
                                            </h5>
                                        }
                                        <p
                                            dangerouslySetInnerHTML={{ __html: featureProcedure.description}}
                                        />
                                         {(featureProcedure.wikiLink !== '' && featureProcedure.wikiLink !==  null) &&
                                            <div>
                                                <h6>Wiki uitleg</h6>
                                                <span 
                                                    className = "--link"
                                                    onClick = {(e) => this.handleWikiLink(e, featureProcedure.wikiLink)}
                                                >
                                                    {featureProcedure.wikiLink}
                                                </span>
                                            </div>
                                        }
                                        {(featureProcedure.location !== '' && featureProcedure.location !==  null) &&
                                            <div>
                                                <h6>Locatie</h6>
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: featureProcedure.location}} 
                                                />
                                            </div>
                                        }
                                        {(featureProcedure.tablename !== '' && featureProcedure.tablename !==  null) &&
                                            <div>
                                                <h6>Tabel</h6>
                                                <span>{featureProcedure.tablename}</span>
                                            </div>
                                        }
                                        {(featureProcedure.fieldname !== '' && featureProcedure.fieldname !==  null) &&
                                            <div>
                                                <h6>Veld</h6>
                                                <span>{featureProcedure.fieldname}</span>
                                            </div>
                                        }
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                        <div>
                            <h4>Procedures development</h4>
                            {this.state.featureDevelopment && this.state.featureDevelopment.map((featureDevelopment) => 
                                <React.Fragment>
                                    <div
                                        className="container--card-wiki__feature--step"
                                    >
                                        {(featureDevelopment.step !== '' && featureDevelopment.step !== null) &&
                                        <React.Fragment>
                                            <h5>STAP {featureDevelopment.step}</h5>
                                            {(featureDevelopment.title !== '' && featureDevelopment.title !== null) &&
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: ':&nbsp'}} 
                                                />
                                            }
                                            </React.Fragment>
                                        }
                                        {(featureDevelopment.title !== '' && featureDevelopment.title !== null) &&
                                            <h5>
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: featureDevelopment.title}} 
                                                />
                                            </h5>
                                        }
                                        <p
                                            dangerouslySetInnerHTML={{ __html: featureDevelopment.description}}
                                        />
                                        {(featureDevelopment.location !== '' && featureDevelopment.location !==  null) &&
                                            <div>
                                                <h6>Locatie</h6>
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: featureDevelopment.location}} 
                                                />
                                            </div>
                                        }
                                        {(featureDevelopment.tablename !== '' && featureDevelopment.tablename !==  null) &&
                                            <div>
                                                <h6>Tabel</h6>
                                                <span>{featureDevelopment.tablename}</span>
                                            </div>
                                        }
                                        {(featureDevelopment.fieldname !== '' && featureDevelopment.fieldname !==  null) &&
                                            <div>
                                                <h6>Veld</h6>
                                                <span>{featureDevelopment.fieldname}</span>
                                            </div>
                                        }
                                    </div>
                                 </React.Fragment>
                            )}
                        </div>
                        <div>
                            <h4>Opmerkingen</h4>
                            {this.state.featureRemarks && this.state.featureRemarks.map((featureRemark) => 
                                <div>
                                    {(featureRemark.title !== '' && featureRemark.title !== null) &&
                                        <h5>{featureRemark.title}</h5>
                                    }
                                    <p
                                        className={featureRemark.alert === 1 ? "wiki-alert": ""}
                                        dangerouslySetInnerHTML={{ __html: featureRemark.description}}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(WikiFeature);
